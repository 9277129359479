import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HtmlTooltipComponent } from './html-tooltip.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[htmlTooltip]'
})
export class HtmlTooltipDirective implements OnDestroy {
  @Input('htmlTooltip') tooltipContent: string = '';
  @Input('htmlTooltipPosition') position: 'above' | 'below' | 'left' | 'right' = 'above';
  @Input('htmlTooltipClass') tooltipClass: string = '';
  @Input('htmlTooltipHideDelay') hideDelay: number = 400; // Default 400ms delay
  
  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<HtmlTooltipComponent>;
  private hideTimeoutId: any;
  private isMouseOverTooltip = false;
  private destroy$ = new Subject<void>();
  
  // Animation timings
  private readonly animationOpenTime = 200; // Match enter animation time (ms)
  private readonly animationCloseTime = 100; // Match exit animation time (ms)

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    this.closeTooltip(0); // Close immediately on destroy
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('mouseenter')
  showTooltip(): void {
    // Clear any existing hide timeout
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }
    
    if (this.tooltipContent && !this.overlayRef) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions(this.getPositions());

      // Determine panel class based on position
      const panelClass = this.getPanelClass();

      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: panelClass
      });

      const tooltipPortal = new ComponentPortal(HtmlTooltipComponent, this.viewContainerRef);
      this.tooltipRef = this.overlayRef.attach(tooltipPortal);
      
      this.tooltipRef.instance.content = this.tooltipContent;
      this.tooltipRef.instance.tooltipClass = this.tooltipClass;
      
      // Subscribe to tooltip mouse events
      this.tooltipRef.instance.tooltipMouseEnter
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isMouseOverTooltip = true;
          // Clear any pending hide operations
          if (this.hideTimeoutId) {
            clearTimeout(this.hideTimeoutId);
            this.hideTimeoutId = null;
          }
        });
      
      this.tooltipRef.instance.tooltipMouseLeave
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isMouseOverTooltip = false;
          this.initiateCloseTooltip();
        });
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    // Only initiate closing if the mouse is not over the tooltip
    if (!this.isMouseOverTooltip) {
      this.initiateCloseTooltip();
    }
  }

  initiateCloseTooltip(): void {
    // Only close if mouse is not over tooltip
    if (this.overlayRef && !this.isMouseOverTooltip) {
      this.hideTimeoutId = setTimeout(() => {
        this.closeTooltip(0);
        this.hideTimeoutId = null;
      }, this.hideDelay);
    }
  }

  closeTooltip(delay: number = this.hideDelay): void {
    // Only close if mouse is not over tooltip
    if (this.isMouseOverTooltip) {
      return;
    }
    
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }
    
    if (delay > 0) {
      this.hideTimeoutId = setTimeout(() => {
        this.performClose();
        this.hideTimeoutId = null;
      }, delay);
    } else {
      this.performClose();
    }
  }

  private performClose(): void {
    if (this.overlayRef) {
      // Allow animation to complete before actually removing
      setTimeout(() => {
        if (this.overlayRef) {
          this.overlayRef.detach();
          this.overlayRef.dispose();
          this.overlayRef = null;
          this.isMouseOverTooltip = false;
        }
      }, this.animationCloseTime);
    }
  }

  private getPanelClass(): string[] {
    const baseClass = 'html-tooltip-panel';
    let positionClass: string;
    
    switch (this.position) {
      case 'above':
        positionClass = 'tooltip-position-above';
        break;
      case 'below':
        positionClass = 'tooltip-position-below';
        break;
      case 'left':
        positionClass = 'tooltip-position-left';
        break;
      case 'right':
        positionClass = 'tooltip-position-right';
        break;
      default:
        positionClass = 'tooltip-position-above';
    }
    
    return [baseClass, positionClass];
  }

  private getPositions() {
    const positions = [];

    switch (this.position) {
      case 'above':
        positions.push({
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8 // Add some offset for the arrow
        });
        break;
      case 'below':
        positions.push({
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 8 // Add some offset for the arrow
        });
        break;
      case 'left':
        positions.push({
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -8 // Add some offset for the arrow
        });
        break;
      case 'right':
        positions.push({
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 8 // Add some offset for the arrow
        });
        break;
    }

    // Add fallback positions
    positions.push(
      { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -8 },
      { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 8 }
    );

    return positions;
  }
} 