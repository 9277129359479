import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter, Output, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-html-tooltip',
  template: `
    <div class="html-tooltip" 
         [ngClass]="tooltipClass" 
         (mouseenter)="onMouseEnter()"
         (mouseleave)="onMouseLeave()"
         [@tooltipAnimation]="'visible'">
      <div [innerHTML]="sanitizedContent"></div>
    </div>
  `,
  styles: [],
  animations: [
    trigger('tooltipAnimation', [
      state('void', style({
        opacity: 0,
        transform: 'scale(0.9)'
      })),
      state('visible', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      transition('void => visible', [
        animate('200ms cubic-bezier(0.23, 1, 0.32, 1)')
      ]),
      transition('visible => void', [
        animate('100ms cubic-bezier(0.23, 1, 0.32, 1)')
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HtmlTooltipComponent {
  @Input() tooltipClass: string = '';
  @Output() tooltipMouseEnter = new EventEmitter<void>();
  @Output() tooltipMouseLeave = new EventEmitter<void>();
  
  private _content: string = '';
  
  get content(): string {
    return this._content;
  }
  
  @Input()
  set content(value: string) {
    this._content = value;
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  
  sanitizedContent: SafeHtml;
  
  constructor(private sanitizer: DomSanitizer, private elementRef: ElementRef) {}

  onMouseEnter(): void {
    this.tooltipMouseEnter.emit();
  }

  onMouseLeave(): void {
    this.tooltipMouseLeave.emit();
  }
} 